export default function Style(theme) {
    const style = {
        root: {
            margin: 10,
            '& .MuiTextField-root': {
                margin: 1,
                width: 200,
            },
        },
    }
    return style
}