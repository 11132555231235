export default  {
    registerUsers: 'https://sso.zootzle.com/users/register',
    authorizeUsers: 'https://sso.zootzle.com/authorize',
    encryptedRegister: 'https://sso.lexie.xyz/encrypted/register',
    encryptedAuthorize: 'https://sso.lexie.xyz/encrypted/login',
    getConversations: 'https://messages.lexie.xyz/conversations',
    postConversations: 'https://messages.lexie.xyz/conversations',
    getConversationsMessages: 'https://messages.lexie.xyz/conversations/',
    getUser: 'https://sso.lexie.xyz/users/',
    postMessages: 'https://messages.lexie.xyz/conversations/messages',
    getRadugalanAlbum: function(path) {return 'https://api.radugalan.com/files?path=' + path + '&width=1024&height=1024'},
    getAlbums: 'https://api.radugalan.com/files',
    getImages: function(page) {return 'https://api.radugalan.com/images?page=' + page },
    getRadugalanFile: function(path) { return 'https://api.radugalan.com/file?thumb=1&path='+path + '&width=1024&height=1024'}
}
