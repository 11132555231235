import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import SinglePageContentMaterial from "./MaterialComponents/SinglePageContentMaterial";

export default class SinglePageComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.loadComponentsProps()

    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps() {
        this.setComponentsConstants('page', {
            content: this.props.content
        })
    }

    getRender() {
        return <SinglePageContentMaterial {...this.buildComponentProps('page')} />
    }
}