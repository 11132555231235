import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Style from "../Styles/SumInputTextFieldStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function OnesButton (props) {
    const classes = useStyles();

    const componentConstants = {
        value: props.componentConstants.value,
        sumPosition: props.componentConstants.sumPosition
    }

    const componentProps = {
        id: "sum"+props.componentConstants.index,
        label: "",
        className: classes.root,
        maxLength: 1,
        InputProps: {
            inputProps: {
                maxLength: 1,
            }
        },
    }
    const setSum = function (e) {
        props.componentMethods.setSum(props.componentConstants.sumPosition, e.target.value)
    }
    return <TextField {...componentProps} {...props.componentProps} onChange = {(e) => {setSum(e)}} />
}