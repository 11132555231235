import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import {Drawer} from "@material-ui/core";
import DrawerMaterial from "./MaterialComponents/DrawerMaterial";

export default class AppbarComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('drawer', {
            menuItems: this.props.menuItems,
            menuItemsTranslations: that.getAllTranslations(that.props.getManagerState().language),
            history: this.props.history,
            language: this.props.getManagerState().language,
            logo: this.props.logo
        }).setComponentsMethods('drawer', {
            setManagerState: (object) => {
                that.setManagerState(object)
            },
            getManagerState: () => {
                return this.props.getManagerState()
            }
        })
    }
    setManagerState(object) {
        const that = this
        this.props.setManagerState(object, () => {
            that.refresh()
        })
    }
    getRender() {
        this.loadComponentsProps()
        return <div>
                    <DrawerMaterial isOpen={this.props.isOpen} history={this.props.history} {...this.buildComponentProps('drawer')} />
                </div>;
    }
}