import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/AddPasswordFormStyle";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SkeletonMaterial (props) {
    const classes = useStyles();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const [title, setTitle] = useState('')
    const [password, setPassword] = useState('')
    const [description, setDescription] = useState('')
    if(props.componentConstants.reset) {
        setTitle('')
        setPassword('')
        setDescription('')
        props.componentConstants.reset = false;
    }
    return <form className={classes.root} noValidate autoComplete="off" {...componentProps}>
            <TextField id="title" type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}
            fullWidth label={componentConstants.labelTitle} />
            <TextField id="password" type="password" value={password} onChange={(e) => {setPassword(e.target.value)}}
            fullWidth label={componentConstants.labelPassword} />
            <TextField multiline rows={4} id="description" type="text" value={description}
            onChange={(e) => {setDescription(e.target.value)}} fullWidth label={componentConstants.labelDescription} />
            <Button className={classes.button} variant="contained" color="primary" disableElevation fullWidth
            endIcon={<AddBoxIcon />}
            onClick={() =>
                {props.componentMethods.appendToPasswordManagerArray({title: title, password: password, description: description})}
            } >
                {componentConstants.labelAddButton}!
            </Button>
        </form>
}