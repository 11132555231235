import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import LexieLogoMaterial from "./MaterialComponents/LexieLogoMaterial";

export default class LexieLogoComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.loadComponentsProps()

    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps() {
    }

    getRender() {
        return <LexieLogoMaterial />;
    }
}