import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Style from "../Styles/BytesButtonStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function BytesButton (props) {
    const classes = useStyles();

    const componentConstants = {
        value: props.componentConstants.value
    }
    const componentProps = {
        className: classes.root,
        size: "small",
    }

    return <Button {...componentProps} {...props.componentProps} onClick={props.componentMethods.onClick}>
                {props.componentConstants.label}
            </Button>
}