import orange from "@material-ui/core/colors/orange";
export default function Style(theme) {
    const style = {
        root: {
            margin: 10,
            '& .MuiTextField-root': {
                margin: 1,
                width: 200,
            },
        },
        letter6: {
            color: orange[50],
            fontWeight: 'bold'
        },
        letter5: {
            color: orange[100],
            fontWeight: 'bold'
        },
        letter4: {
            color: orange[200],
            fontWeight: 'bold'
        },
        letter3: {
            color: orange[300],
            fontWeight: 'bold'
        },
        letter2: {
            color: orange[400],
            fontWeight: 'bold'
        },
        letter1: {
            color: orange[500],
            fontWeight: 'bold'
        }
    }
    return style
}