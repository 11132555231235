import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import PagesContentMaterial from "./MaterialComponents/PagesContentMaterial";

export default class PagesComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.loadComponentsProps()

    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps() {
        this.setComponentsConstants('page', {
            content: this.props.content
        })
    }

    getRender() {
        return <PagesContentMaterial {...this.buildComponentProps('page')} />
    }
}