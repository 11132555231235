import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/SinglePageContentStyle";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SinglePageContentMaterial (props) {
    const classes = useStyles();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants
    }
    return <div  {...componentProps} className={classes.root}>
                <Grid container spacing={0} container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">
                        <Grid item xs={12} lg={6} md={12}>
                            <Paper className={classes.paper}>
                                    {componentConstants.content}
                            </Paper>
                         </Grid>
                </Grid>
            </div>
}