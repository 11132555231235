import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/EmailTextFiledStyle";
import EmailTextField from './EmailTextField';
import PasswordTextField from './PasswordTextField';
import LoginButton from './LoginButton';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function LoginForm(props) {
    const classes = useStyles();

    const componentProps = {
        fullWidth: true,
        name: 'email',
        id: 'email',
        type: "email",
        className: classes.root,
        value: ''
    }


    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    return <form>
            <EmailTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <PasswordTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <LoginButton  componentConstants={componentConstants} componentMethods={componentMethods} />
        </form>
}
