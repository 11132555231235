import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/EmailTextFiledStyle";
import EmailTextField from './EmailTextField';
import PasswordTextField from './PasswordTextField';
import ConfirmPasswordTextField from './ConfirmPasswordTextField';
import DateOfBirthField from './DateOfBirthField';
import FirstNameTextField from './FirstNameTextField';
import LastNameTextField from './LastNameTextField';
import SexField from './SexField';
import RegisterButton from './RegisterButton';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function RegisterForm(props) {
    const classes = useStyles();

    const componentProps = {
        fullWidth: true,
        name: 'email',
        id: 'email',
        type: "email",
        className: classes.root,
        value: ''
    }


    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const handleChange = (event) => {
        props.componentMethods.setPassword(event.target.value);
    };

    return <form>
            <FirstNameTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <LastNameTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <EmailTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <DateOfBirthField componentConstants={componentConstants} componentMethods={componentMethods} />
            <PasswordTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <ConfirmPasswordTextField componentConstants={componentConstants} componentMethods={componentMethods} />
            <SexField componentConstants={componentConstants} componentMethods={componentMethods} />
            <RegisterButton  componentConstants={componentConstants} componentMethods={componentMethods} />
        </form>
}
