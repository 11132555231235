import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/EmailTextFiledStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function SexField(props) {
    const classes = useStyles();

    const componentProps = {
        label: props.componentConstants.labelSex,
        fullWidth: true,
        name: 'email',
        id: 'email',
        type: "email",
        className: classes.root,
        value: props.componentConstants.valuePassword
    }

    const componentConstants = {
    }

    const handleChange = (event) => {
        props.componentMethods.onChangeSex(event.target.value);
    };

    return <TextField {...componentProps} {...props.componentProps} onChange={handleChange} />
}
