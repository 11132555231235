import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/LexieLogoStyle";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function LexieLogoMaterial (props) {
    const classes = useStyles();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }

    const componentMethods = {
        ...props.componentMethods
    }
    return <React.Fragment  {...componentProps}>
                <Typography variant="h4" className={classes.letter6}>Lexie Encryption</Typography>
            </React.Fragment>
}