import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Style from "../Styles/RegisterButtonStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function LoginButton (props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentProps = {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        className: classes.root,
        onClick: () => { props.componentMethods.ajaxLogin() }
    }

    return <Button {...componentProps} >
        {componentConstants.labelLoginButton}
</Button>
}