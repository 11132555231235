import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import SubmenuMaterial from "./SubmenuMaterial";
import {Button} from "@material-ui/core";
import Style from "../Styles/MenuMaterialStyle";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function MenuMaterial(props) {
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState({});

    const handleClick = (url) => {
        let setValue = open[url]
        if(!setValue)
            setValue = true
        open[url] = setValue
        setOpen(open);
    };
    return (
        <List
    component="nav"
    aria-labelledby="nested-list-subheader"
    className={classes.root}
        >
        {
            Object.keys(componentConstants.menuItems).map((value, index) => {
                if(componentConstants.menuItems[value].visible === false) {
                    return null
                }
                if(componentConstants.menuItems[value].children) {
                    return <SubmenuMaterial key = {index} componentConstants={
                                        {...componentConstants, text:props.componentConstants.menuItemsTranslations[value], open :open[componentConstants.menuItems[value].url], menuItems: componentConstants.menuItems[value].children}
                                    } componentMethods={componentMethods} />
                } else {
                    const link = <Link to={'/' + props.componentMethods.getManagerState().language + '/' + props.componentConstants.menuItems[value].url} className={classes.menuButtonLink} color="primary">
                                    <Button fullWidth color="primary" startIcon={<PhotoAlbumIcon />}>
                                        {props.componentConstants.menuItemsTranslations[value]?props.componentConstants.menuItemsTranslations[value]:componentConstants.menuItems[value].menuItem}
                                    </Button>
                                </Link>
                    return  <ListItem key={index}>
                                <ListItemText primary= {link} />
                                <Divider />
                            </ListItem>
                }
                })
        }

        </List>
);
}