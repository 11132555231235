import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import EncryptionDialogComponent from "../../CommonComponents/EncryptionDialog/EncryptionDialogComponent";
import LexieEncryption from "../../Vendor/LexieEncryption/LexieEncryption";
import TextTextareaAutosize from "./MaterialComponents/EncryptedTextTextareaAutosize";
import Grid from "@material-ui/core/Grid";
import EncryptButton from "./MaterialComponents/EncryptButton";
import StringParser from "../../Vendor/LexieEncryption/StringParser";

export default class EncryptComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.state = {
            encryptedText: '',
            decryptedText: '',
            unEncryptedText: null
        }
        this.loadComponentsProps()
    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps() {
        this.setComponentsMethods('encryptionDialog', {
            setEncryption: (encryptionObject) => {this.setEncryption(encryptionObject)}
        }).setComponentsProps('gridItem4',{
            xs: 4,
            lg: 4,
            md: 4,
            item: true
        })
        .setComponentsProps('gridItem6',{
            xs: 6,
            lg: 6,
            md: 6,
            item: true
        })
        .setComponentsProps('gridItem12',{
            xs: 12,
            lg: 12,
            md: 12,
            item: true
        })
        .setComponentsMethods('buttonEncrypt', {
            encryptText: () => {this.encryptText()}
        })
        .setComponentsConstants('buttonEncrypt', { text: this.getTranslation(this.props.lang, 'LANG_ENCRYPT' )})
        .setComponentsMethods('buttonDecrypt', {
            encryptText: () => {this.decryptText()}
        })
        .setComponentsConstants('buttonDecrypt', { text:this.getTranslation(this.props.lang, 'LANG_DECRYPT') })
        .setComponentsMethods('textareaUnecryptedText', {
            setValue: (e) => {this.setEncryptText(e)}
        })
        .setComponentsMethods('textareaEncryptedText', {
            setValue: (e) => {}
        })
        .setComponentsMethods('textareaDecryptedText', {
            setValue: (e) => {}
        })
        .setComponentsConstants('textareaEncryptedText', {useValue: true, value : this.state.encryptedText})
        .setComponentsConstants('textareaUnecryptedText', {useValue: false})
        .setComponentsConstants('textareaDecryptedText', {useValue: true, value : this.state.decryptedText})
        .setComponentsConstants('encryptionDialog', {lang: this.props.lang})
    }

    setEncryption(object) {
        const encryption = new LexieEncryption(object.zeros, object.ones, object.sum.join(''))
        this.setState({encryption: encryption})
    }
    setEncryptText(e) {
        this.setState({unEncryptedText: e.target.value}, ()=> {this.refresh()})
    }

    encryptText() {
        let managerState = this.state
        let parser = new StringParser(managerState.encryption, this.state.unEncryptedText);
        let encryptedText = parser.encryptString().join(' ')
        if(encryptedText) {
            this.setState({encryptedText:encryptedText}, () => {this.refresh()})
        }

    }

    decryptText() {
        let managerState = this.state
        let parser = new StringParser(managerState.encryption, this.state.unEncryptedText);
        let decryptedText = parser.decryptByteArray(this.state.encryptedText.split(' '))
        if(decryptedText) {
            this.setState({decryptedText:decryptedText}, () => {this.refresh()})
        }

    }

    getRender() {
        this.loadComponentsProps()
        return <React.Fragment>
            {this.getRenderEncryptionDialog()}
            {this.state.encryption && this.state.encryption.isValidEncryption()? this.getRenderGridEncrypt(): null}
        </React.Fragment>
    }

    getRenderEncryptionDialog() {
        return <EncryptionDialogComponent {...this.buildComponentProps('encryptionDialog')} />
    }

    getRenderGridEncrypt() {
        return <Grid container>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaUnecryptedText')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <EncryptButton {...this.buildComponentProps('buttonEncrypt')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaEncryptedText')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <EncryptButton {...this.buildComponentProps('buttonDecrypt')} />
                    </Grid>
                    <Grid {...this.getComponentsProps('gridItem12')} >
                        <TextTextareaAutosize {...this.buildComponentProps('textareaDecryptedText')} />
                    </Grid>
                </Grid>
    }
}
/**

 */