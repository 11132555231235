import React, { useState } from 'react';
import Routes from '../Routes'
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import PagesComponent from "../../../../Components/PagesComponent/PagesComponent";
import Pages from "../Data/Pages";
import PasswordManagerComponent from "../../../../Components/PasswordManagerComponent/PasswordManagerComponent";
import SinglePageComponent from "../../../../Components/SinglePageComponent/SinglePageComponent";
import Languages from '../../../../Settings/Languages'
import Content from '../Data/Content'

export default function RouterSwitch(props) {
    let routesContents = {}
    Object.keys(Routes).map((routesValue, routesIndex) => {
        Languages.map((valueLang, indexLang) => {
            routesContents['/' + Languages[indexLang] + '/' + Routes[routesValue].url] =
                    Content(Routes[routesValue], Languages[indexLang], props)
        })
    })
    Languages.map((valueLang, indexLang) => {
        routesContents['/' + Languages[indexLang] + '/alb'] = Content({url: '/' + Languages[indexLang] + '/alb'}, Languages[indexLang], props)
        routesContents['/' + Languages[indexLang] ] = Content({url: ''}, Languages[indexLang], props)

    })
     routesContents['/'] = Content({url: ''}, Languages[0], props)
    return (<Switch>
                {
                    Object.keys(routesContents).map((routesValue, routesIndex) => {
                                return <Route key={routesIndex} path={routesValue}>
                                            {routesContents[routesValue]}
                                        </Route>
                    })
                }
            </Switch>);
}