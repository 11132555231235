import React from "react";
import {makeStyles } from '@material-ui/core/styles';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
import { MuiThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import LANGUAGES from '../Settings/Languages'
import lang from "../Languages/Languages";
import Theme from '../Themes/Theme'
import Grid from '@material-ui/core/Grid';

class CoreComponent extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            render: true,
            language:  LANGUAGES[0],
            errors: {
                fields: {},
                message: ''
            }
        }
        this.componentsProps = {}

        this.responses = {
            success: {
                success: true,
                data: [],
                status: 200
            },
            forbidden: {
                success: false,
                errorMessages: [],
                status: 404
            },
            invalid: {
                success: false,
                errorMessages: [],
                validationMessages: [],
                status: 403
            },
            error: {
                success: false,
                errorMessages: [],
                status: 500
            }
        }
        if(props.Theme)
            this.muiTheme = props.Theme
        else
            this.muiTheme = Theme
    }
    componentDidMount() {
        this.useTheme(this.muiTheme)
    }

    setComponentsProps(componentName, componentProps) {
        this.getComponentsProps(componentName)
        this.componentsProps[componentName].componentProps = componentProps
        return this
    }
    setComponentsMethods(componentName, componentMethods) {
        this.getComponentMethods(componentName)
        this.componentsProps[componentName].componentMethods = componentMethods
        return this
    }
    setComponentsConstants(componentName, componentConstant) {
        this.getComponentConstants(componentName)
        this.componentsProps[componentName].componentConstants = componentConstant
        return this
    }
    getComponentsProps(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentProps
    }
    getComponentMethods(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentMethods
    }
    getComponentConstants(componentName) {
        if(this.componentsProps[componentName] === undefined)
            this.componentsProps[componentName] = {}
        return this.componentsProps[componentName].componentConstants
    }
    buildComponentProps(componentName) {
        return {
            componentProps: this.getComponentsProps(componentName),
            componentMethods: this.getComponentMethods(componentName),
            componentConstants: this.getComponentConstants(componentName),
        }
    }
    loadComponentsProps(reload = false) {
        if (reload) {
            this.refresh()
        }
    }
    ajax (url, 
        dataObject = {}, 
        method, stateValue = '', 
        callback = () => {}, 
        headers = {}, 
        setState = true, 
        catchCallback = () => {}) {
        // adauga o fct de callback pe eroare (catchCallback) pe care o executi in catch
        let sendDataObj = {};
        let dataURLSearchParams = new URLSearchParams();
        for (let pDO in dataObject) {
            if (dataObject.hasOwnProperty(pDO)) {
                sendDataObj[pDO] = dataObject[pDO];
                dataURLSearchParams.append(pDO, dataObject[pDO]);
            }
        }

        let stateSet = {};
        let requestObject = {
            method: method,
            mode: "cors",
            cache: "no-cache",
            headers: headers,
        };

        switch(method) {
            case 'GET':
                break;
            default:
                requestObject.body = dataURLSearchParams
                break;
        }

        fetch(url, requestObject).then((response) => {
            const res = response.json();
            return res;
        }).then((data) => {
            if (data.success === true || data.httpStatusCode === 200) {
                stateSet[stateValue] = data;
                if (setState) {
                    this.setState(stateSet, () => {
                        callback(data);
                    })
                }
            } else {
                catchCallback();
            }
        }).catch(error => {
        });
    }

    setStateFromProps(state) {
        if(state !== undefined)
            this.setState(state);
    }

    getQueryParams (param) {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParam = urlParams.get(param);
        return queryParam;
    }

    getDataStructure () {
        return {
            success: false,
            status: 200,
            data: []
        }
    }
    getTranslation(language, translationKey) {
        return lang[language][translationKey]
    }
    getAllTranslations(language) {
        return lang[language];
    }
    setLanguage(lang, callback = () => {} ) {
        if(typeof lang === "number")
            this.setState({lang: LANGUAGES[lang]}, callback)
        else
            this.setState({lang: lang}, callback)
    }
    setStateTextFeldValue(value, name) {
        let setObject = {}
        setObject[name] = value
        this.setState(setObject)
    }
    useTheme(theme) {
        this.muiTheme = theme
        return this
    }
    render() {
        return <MuiThemeProvider theme={this.muiTheme}>
            { this.getRender() }
            </MuiThemeProvider>
    }

    historyPush(path) {
        this.props.history.push(path)
    }

    getRender() {
        return <div>This is the core component. This component should be inherited</div>
    }

    getRenderReponsiveContainer(content) {
        this.setComponentsProps('reponsiveGridItemContainer', {
            xs: 12,
            lg: 6,
            md: 6
        }).setComponentsProps('responsiveGridItemMargin', {
            xs: 0,
            lg: 3,
            md: 3
        })
        return <Grid container>
            <Grid {...this.getComponentsProps('responsiveGridItemMargin')}>
            </Grid>
            <Grid {...this.getComponentsProps('responsiveGridItemContainer')}>
                {content}
            </Grid>
                <Grid {...this.getComponentsProps('responsiveGridItemMargin')}>
            </Grid>
        </Grid>
    }

    getLocalStorage() {
        // We need the try block because user may not permit our accessing localStorage.
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) { // The key 'state' does not exist.
                return undefined;             // Let our reducer initialize the app.
            }

            return JSON.parse(serializedState)

        } catch (error) {
            return undefined // Let our reducer initialize the app.
        }
    }

    saveLocalStorage(storageObject, storageName) {
        try {
            localStorage.setItem(storageName, JSON.stringify(storageObject))
        } catch (error) {
            console.log(error)
        }
    }
    refresh() {
        this.loadComponentsProps();
        this.forceUpdate();
    }
}


export default CoreComponent;
