import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Style from "../Styles/SubmenuMaterialStyle";

const useStyles = makeStyles((theme) => (Style(theme)));

export default function MenuMaterial(props) {
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentConstants
    }

    const componentProps = {
        ...props.componentProps
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (<React.Fragment>
                <ListItem button onClick={() => {handleClick()}}>
                    <ListItemText primary= {componentConstants.text} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    <Divider />
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.nested}>
                {
                    Object.keys(componentConstants.menuItems).map((value, index) => {
                        const linkEl = <Link to={'/'+ props.componentMethods.getManagerState().language + '/'
                                + props.componentConstants.menuItems[value].url}
                        className={classes.submenuButtonLink} color="primary" >
                            {props.componentConstants.menuItemsTranslations[value]}
                            </Link>
                        return  <ListItem button key={index}>
                            <ListItemText primary={linkEl}  />
                        <Divider />
                        </ListItem>
                    })
                }
                </List>
                </Collapse>
            </React.Fragment>)
};
