import { fade } from '@material-ui/core/styles';

const drawerWidth = 240;

export default function Style(theme) {
    const style = {
        root: {
            width: '100%',
                maxWidth: 360,
                backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        menuButtonLink: {
            width: '100%',
            height: '100%',
            textDecoration: 'none',
            color: theme.palette.primary.main,
            textAlign: 'left'
        },
    }
    return style
}