export default function Style(theme) {
    const style = {
        root: {
            width: (100/9  - 1) + '%',
            margin: 0.5 + '%',
            display: 'inline-block',
            color: theme.palette.common.black
        }
    }
    return style
}