import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Style from "../Styles/RegisterButtonStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function RegisterButton (props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }
    const registerContent = {
      usersEmail: componentConstants.valueEmail,
      usersPassword: componentConstants.valuePassword,
      usersFirstName: componentConstants.valueFirstName,
      usersLastName: componentConstants.valueLastName,
      usersConfirmPassword: componentConstants.valueConfirmPassword,
      usersDateOfBirth: componentConstants.valueDateOfBirth,
      usersUsersSexID: componentConstants.valueSex
  }
    const componentProps = {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        className: classes.root,
        onClick: () => { console.log(componentConstants); props.componentMethods.ajaxRegister(registerContent) }
    }

    return <Button {...componentProps} >
        {componentConstants.labelRegisterButton}
</Button>
}