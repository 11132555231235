import React from "react";
import Routes from "../Routes";
import PagesComponent from "../../../../Components/PagesComponent/PagesComponent";
import SinglePageComponent from "../../../../Components/SinglePageComponent/SinglePageComponent";
import PasswordManagerComponent from "../../../../Components/PasswordManagerComponent/PasswordManagerComponent";
import Pages from "./Pages";
import {withRouter} from 'react-router';
import LexieArchiveComponent from "../../../../Components/LexieArchiveComponent/LexieArchiveComponent";

export default function Content(route, lang, props) {
    const data = {
        ['/' + lang + '/' + Routes.encryption.url]: <React.Fragment>
                                <PagesComponent getManagerState={props.getManagerState}
                                setManagerState={props.setManagerState}
                                language={lang}
                                content={Pages(lang)} />
                            </React.Fragment>,
        ['/' + lang + '/' + Routes.passwordManager.url]:  <SinglePageComponent getManagerState={props.getManagerState}
                                        setManagerState={props.setManagerState}
                                        content={<PasswordManagerComponent
                                        language={lang}
                                        getManagerState={props.getManagerState}
                                        setManagerState={props.setManagerState} />} />,
        ['/' + lang + '/' + Routes.fileEncryptor.url]:  <SinglePageComponent getManagerState={props.getManagerState}
                                        setManagerState={props.setManagerState}
                                        content={<LexieArchiveComponent
                                        language={lang}
                                        getManagerState={props.getManagerState}
                                        setManagerState={props.setManagerState} />} />,
    }
    return data['/' + lang + '/' + route.url]
}