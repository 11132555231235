import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Style from "../Styles/SetEncryptionValuesButtonStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function BytesButton (props) {
    const classes = useStyles();

    const componentConstants = {
        label: props.componentConstants.label,
    }
    const componentProps = {
        className: classes.root,
        size: "large",
        fullWidth: true,
        color: "secondary",
        variant: "contained"
    }

    return <Button {...componentProps} {...props.componentProps} onClick={props.componentMethods.onClick}>
                {componentConstants.label}
            </Button>
}