import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import RouterSwitch from "./Components/RouterSwitch";
import history from './history';
import Languages from "../../../Settings/Languages";
import RouterNavbar from "./Components/RouterNavbar";
import Content from './Data/Content'
import Routes from './Routes'

class RouterComponent extends React.Component {
    constructor(props) {
        super(props);

        let lang = window.location.pathname.split('/')[1]
        if(lang === '')
            lang = Languages[0]
        this.state = {
            language: lang,
        }
    }
    componentDidMount() {
    }
    setManagerState(stateObject, callback = () => {}) {
        if(stateObject) {
            let that = this
            this.setState(stateObject,() => {
                this.refresh(callback)
            })
        }
    }
    getManagerState() {
        return this.state
    }
    render() {
        return (
            <Router history={history}>
                <RouterNavbar history={history} getManagerState={() => { return this.getManagerState()}}
                setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}}
                componentConstants={{Routes: Routes}} />
                <RouterSwitch
                history={history} getManagerState={() => { return this.getManagerState()}}
                setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}} />
            </Router>
        );
    }
    refresh(callback = () => {}) {
        this.forceUpdate()
        callback()
    }
}

export default RouterComponent