import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/EncryptedTextTextareaAutosizeStyle";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AuthenticateButton (props) {
    const classes = useStyles();
    const componentProps = {
        placeholder: "Text",
        "aria-label": "minimum height",
        className: classes.root,
        multiline: true,
        rows: 4
    }
    if(props.componentConstants.useValue)
        componentProps.value = props.componentConstants.value
    const componentMethods = {
        onChange: props.componentMethods.setValue
    }
    return <TextField {...componentProps}  {...componentMethods} />
}