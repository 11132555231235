import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import PasswordFiledsList from "./MaterialComponents/PasswordFiledsList";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddPasswordForm from "./MaterialComponents/AddPasswordForm";
import LexieEncryptionDialogComponent
    from "../../CommonComponents/LexieEncryptionDialog/LexieEncryptionDialogComponent";
import LexieEncryption from "../../Vendor/LexieEncryption/LexieEncryption";
import List from '@material-ui/core/List';
import StringParser from '../../Vendor/LexieEncryption/StringParser'
import JsonInput from './MaterialComponents/JsonInput'
import Divider from '@material-ui/core/Divider';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';

export default class PasswordManagerComponent extends CoreComponent {
    constructor(props) {

        super(props);
        this.loadComponentsProps()
        this.state = {
            passwordManagerArray: [],
            language: props.language,
            filterPasswords: ''
        }
    }

    componentDidMount() {

        super.componentDidMount()

    }
    loadComponentsProps(reload = false) {
        this.setComponentsConstants('JsonInput', {
            labelNewButton: this.getTranslation(this.props.language, 'LANG_NEW'),
            labelCopyButton: this.getTranslation(this.props.language, 'LANG_COPY'),
            labelDownloadButton: this.getTranslation(this.props.language, 'LANG_DOWNLOAD'),
            labelLoadButton: this.getTranslation(this.props.language, 'LANG_LOAD_FILE'),
            labelTextField: this.getTranslation(this.props.language, 'LANG_JSON_ENCRYPTED_TEXT'),
            jsonInputValue:  JSON.stringify(this.state.passwordManagerArray)
        })
        .setComponentsConstants('addPasswordForm', {
            labelPassword: this.getTranslation(this.props.language, 'LANG_PASSWORD'),
            labelDescription: this.getTranslation(this.props.language, 'LANG_DESCRIPTION'),
            labelTitle: this.getTranslation(this.props.language, 'LANG_TITLE'),
            labelAddButton: this.getTranslation(this.props.language, 'LANG_ADD')
        }).setComponentsConstants('encryptionDialogComponent', {
            language: this.props.language,
        })
        .setComponentsMethods('encryptionDialogComponent', {
            setEncryption: (object) => {this.setEncryption(object)}
        })
        .setComponentsMethods('encryptionDialogComponent', {
            setEncryption: (object) => {this.setEncryption(object)}
        })
        .setComponentsMethods('addPasswordForm', {
            appendToPasswordManagerArray: (object) => {
                let passwordManagerArray = this.state.passwordManagerArray
                const encryption = new LexieEncryption(this.state.encryption.zeros, this.state.encryption.ones, this.state.encryption.sum)
                const parser = new StringParser(encryption, object.password);
                object.password = parser.encryptString().join(' ')
                passwordManagerArray.push(object)
                this.setState({passwordManagerArray: passwordManagerArray}, () => {
                    this.loadComponentsProps(true)
                })
                this.setComponentsConstants('addPasswordForm', {
                    reset: true
                })
            }
        })
        .setComponentsMethods('JsonInput', {
            setPasswordManager: (string) => {
                try{
                   const passwordManagerArray = JSON.parse(string)
                   this.setState({passwordManagerArray: passwordManagerArray}, () => {
                       this.loadComponentsProps(true)
                   })
                } catch(e) {
                    console.log(e)
                }

            }
        })

        if(reload) {
            this.refresh()
        }
        return this
    }

    getRender() {
        const checkEncryption = this.state.encryption
        return <React.Fragment>
                    {checkEncryption ? null : this.getRenderLexieEncryptComponent()}
                    {checkEncryption ? this.getRenderResetEncryption() : null}
                    {checkEncryption ? this.getRenderJsonInput() : null}
                    {checkEncryption ? this.getRenderAddPasswordForm() : null}
                    {checkEncryption ? this.getRenderFilterPasswordComponent() : null}
                    {checkEncryption ? this.getRenderPasswordFieldsPaper() : null}
                </React.Fragment>
    }

    setEncryption(object) {
        const encryption = new LexieEncryption(object.zeros, object.ones, parseInt(object.sum.join('')))
        this.setState({encryption: encryption}, () => {
            this.loadComponentsProps(true)
        })
    }

    resetEncryption() {
        this.setState({checkEncryption: null, encryption: null, passwordManagerArray: []}, () => {
            this.loadComponentsProps(true)
        })
    }

    getRenderAddPasswordForm() {
        return <AddPasswordForm {...this.buildComponentProps('addPasswordForm')} />
    }

    getRenderPasswordFieldsPaper() {
        return <List >
        {
            this.state.passwordManagerArray.map((value, index) => {
                const componentConstants = {
                    ...value,
                    labelAddButton: this.getTranslation(this.props.language, 'LANG_ADD'),
                    labelCopied: this.getTranslation(this.props.language, 'LANG_COPIED'),
                    encryption: this.state.encryption
                }
                const that = this
                const componentMethods = {
                    removeFromPasswordManager: () => {
                        let passwordManagerArray = this.state.passwordManagerArray
                        passwordManagerArray.splice(index, 1)
                        this.setState({passwordManagerArray: passwordManagerArray}, () => {
                            that.loadComponentsProps(true)
                        })
                    }
                }
                if(this.state.filterPasswords != '' && value.title.indexOf(this.state.filterPasswords) === -1 && value.description.indexOf(this.state.filterPasswords) === -1)
                    return null
                else 
                return <React.Fragment>
                            <PasswordFiledsList key={index} componentMethods={componentMethods} componentConstants={componentConstants} />
                            <Divider />
                        </React.Fragment>
            })
        }
        </List>
    }
    getRenderFilterPasswordComponent()
    {
        return <TextField id="title" type="text" value={this.state.filterPasswords} onChange={(e) => {this.setState({filterPasswords: e.target.value})}}
        fullWidth label={this.getTranslation(this.props.language, 'LANG_FILTER_PASSWORDS')} />
    }
    getRenderLexieEncryptComponent() {
        return <LexieEncryptionDialogComponent {...this.buildComponentProps('encryptionDialogComponent')} />
    }
    getRenderJsonInput() {
        return <JsonInput {...this.buildComponentProps('JsonInput')} />
    }
    getRenderResetEncryption() {
        return <Button
                       variant="contained"
                       color="secondary"
                       startIcon={<LockOpenIcon />}
                       onClick={() => {this.resetEncryption()}}
                     >
                    {this.getTranslation(this.props.language, 'LANG_RESET_ENCRYPTION')}
               </Button>
    }
}