import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/PasswordTextFiledStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function PasswordTextField(props) {
    const classes = useStyles();

    const componentProps = {
        fullWidth: true,
        name: 'password',
        id: 'password',
        type: "password",
        className: classes.root,
        label: props.componentConstants.labelPassword
    }

    const componentConstants = {
    }

    const handleChange = (event) => {
        props.componentMethods.onChangePassword(event.target.value);
    };

    return <TextField {...componentProps} onChange={handleChange} />
}
