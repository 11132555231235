export default  {
    ro: {
        LANG_AUTHENTICATE: "Autentificare",
        LANG_REGISTER: "Inregistrare",
        LANG_PASSWORD: 'Parola',
        LANG_CONFIRM_PASSWORD: 'Confirm Password',
        LANG_EMAIL: 'E-Mail',
        LANG_FIRSTNAME: 'Prenume',
        LANG_LASTNAME: 'Nume',
        LANG_DESCRIPTION: 'Descriere',
        LANG_TITLE: 'Titlu',
        LANG_ADD: 'Adauga',
        LANG_LANGUAGE: 'Limba',
        LANG_DECRYPT: 'Decripteaza',
        LANG_ENCRYPT: 'Cripteaza',
        LANG_SET_ENCRYPTION: 'Foloseste criptarea',
        LANG_ENCRYPTION_BYTE: 'Octet pentru criptare',
        LANG_ENCRYPTION_SUM: 'Suma pentru criptare',
        LANG_PASTE_JSON: 'Lipeste text JSON',
        LANG_JSON_ENCRYPTED_TEXT: 'Text criptat JSON',
        LANG_NEW: 'Nou',
        LANG_COPY: 'Copiaza',
        LANG_DOWNLOAD: 'Descarca',
        LANG_COPIED: 'Copied',
        LANG_RESET_ENCRYPTION: 'Reseteaza criptarea',
        LANG_LOAD_FILE: 'Incarca fisier',
        LANG_CONFIRMPASSWORD: "Confirma parola",
        LANG_DATEOFBIRTH: "Zi de nastere",
        LANG_LOGIN: "Autentifica-te",
        LANG_SEX: "Sex",
        LANG_CANCEL: "Inchide",
        LANG_MALE: "Masculin",
        LANG_FEMALE: "Feminim",
        LANG_USERNAME: "Utilizator",
        LANG_CONFIRMEMAIL: "Confirma email",
        LANG_ALBUMS: "Albume",
        LANG_ALBUMLOAD_MORE: "Incarca mai multe",
        LANG_ENCRYPTING: "Se cripteaza",
        LANG_DENCRYPTING: "Se decripteaza",
        encryption: "Criptare",
        passwordManager: "Manager parole",
        fileEncryptor: "Criptare fisiere",
        album: "Album",
        LANG_FILTER_PASSWORDS: 'Filtreaza parole'
    },
    en: {
        LANG_AUTHENTICATE: "Authenticate",
        LANG_REGISTER: "Register",
        LANG_PASSWORD: 'Password',
        LANG_CONFIRM_PASSWORD: 'Confirm Password',
        LANG_USERNAME: 'Username',
        LANG_EMAIL: 'E-Mail',
        LANG_FIRSTNAME: 'First Name',
        LANG_LASTNAME: 'Last Name',
        LANG_DESCRIPTION: 'Description',
        LANG_TITLE: 'Titlu',
        LANG_ADD: 'Add',
        LANG_LANGUAGE: 'Language',
        LANG_DECRYPT: 'Decrypt',
        LANG_ENCRYPT: 'Encrypt',
        LANG_SET_ENCRYPTION: 'Set encryption',
        LANG_ENCRYPTION_BYTE: 'Encryption byte',
        LANG_ENCRYPTION_SUM: 'Encryption sum',
        LANG_PASTE_JSON: 'Paste JSON Text',
        LANG_JSON_ENCRYPTED_TEXT: 'Encrypted JSON Text',
        LANG_NEW: 'New',
        LANG_COPY: 'Copy',
        LANG_DOWNLOAD: 'Download',
        LANG_COPIED: 'Copied',
        LANG_RESET_ENCRYPTION: 'Reset encryption',
        LANG_LOAD_FILE: 'Load file',
        LANG_CONFIRMPASSWORD: "Confirm password",
        LANG_DATEOFBIRTH: "Date of birth",
        LANG_LOGIN: "Login",
        LANG_SEX: "Sex",
        LANG_CANCEL: "Cancel",
        LANG_MALE: "Masculin",
        LANG_FEMALE: "Feminim",
        LANG_CONFIRMEMAIL: "Confirm email",
        LANG_ALBUMS: "Albums",
        LANG_ALBUMLOAD_MORE: "Load more",
        LANG_ENCRYPTING: "Encrypting",
        LANG_DENCRYPTING: "Decrypting",
        encryption: "Encryption",
        passwordManager: "Password manager",
        fileEncryptor: "File encryptor",
        album: "Album",
        LANG_FILTER_PASSWORDS: 'Filter passwords'
    }
}
