import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/JsonInputStyle";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SkeletonMaterial (props) {
    const classes = useStyles();

    const componentProps = {
        ...props.componentProps,
    }
    const componentConstants = {
        ...props.componentConstants,
    }
    const componentMethods = {
        ...props.componentMethods,
    }
    const [json, setJson] = useState('')
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const inputFile = useRef(null);

    function copyToClipboard() {
        textAreaRef.current.select();
        document.execCommand('copy');
        setCopySuccess('Copied!');
    }
    const showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
          const text = (e.target.result)
          componentMethods.setPasswordManager(text)
        };
        reader.readAsText(e.target.files[0])
    }

    const downloadFile = async () => {
      const fileName = "passwords";
      const json = textAreaRef.current.value;
      const blob = new Blob([json],{type:'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    function triggerOpenFile() {
        inputFile.current.click()
    }
    return <form className={classes.root} noValidate autoComplete="off" {...componentProps}>
            <TextField inputRef={textAreaRef} multiline rows={4} id="json" type="text" value={props.componentConstants.jsonInputValue}
            onChange={(e) => {componentMethods.setPasswordManager(e.target.value)}}
                fullWidth label={props.componentConstants.labelTextField} />
            <Button onClick={() => { componentMethods.setPasswordManager('[]')} }
                className={classes.button} variant="contained" color="primary" disableElevation
                endIcon={<FiberNewIcon />} >
                {componentConstants.labelNewButton}
            </Button>
            <Button onClick={() => { copyToClipboard() } } className={classes.button}
                variant="contained" color="primary" disableElevation
                endIcon={<FileCopyIcon />} >
                {componentConstants.labelCopyButton}
            </Button>
            <Button onClick={() => { downloadFile() } } className={classes.button}
                variant="contained" color="primary" disableElevation
                endIcon={<GetAppIcon />} >
                {componentConstants.labelDownloadButton}
            </Button>
            <Button onClick={() => { triggerOpenFile() } } className={classes.button}
                variant="contained" color="primary" disableElevation
                endIcon={<PublishIcon />} >
                {componentConstants.labelLoadButton}
            </Button>
            <input style={{display:"none"}} ref={inputFile} type="file" onChange={(e) => showFile(e)} />
        </form>
}