export default {
    passwordManager: {
        url: 'password-manager',
        default: false,
        visible: true,
    },
    encryption: {
        url: 'encryption',
        default: true,
        visible: true,
    },
    fileEncryptor: {
        url: 'file-encrypter',
        default: false,
        visible: true,
    },
}