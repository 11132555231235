import Parser from "./Parser.js";
import LexieEncryption from "./LexieEncryption.js";

export default class FileParser extends Parser {
    constructor(lexieEncryption, callback = (json) => {}, settings = { BYTE_LENGTH : 512}) {
        super(lexieEncryption)
        this.SETTINGS = settings
        this.callback = callback
    }

    encryptFile(File) {
        const that = this
        const myReader = new FileReader();
        myReader.onloadend = (e) => {
            const rez = myReader.result
            const arr = new Uint8Array(rez)
            const bin = [...arr].map((n) => n.toString(2).padStart(8, '0'))
            const archiveJson = that.archiveJson(bin, File.type, File.name)
            archiveJson.bytes  = this.lexieEncryption.encryptObject(archiveJson.bytes)
            that.callback(archiveJson)
        }
        myReader.readAsArrayBuffer(File)
    }
    archiveFile(File) {
        const that = this
        const myReader = new FileReader();
        myReader.onloadend = (e) => {
            const rez = myReader.result
            const arr = new Uint8Array(rez)
            const bin = [...arr].map((n) => n.toString(2).padStart(8, '0'))
            const archiveJson = that.archiveJson(bin, File.type, File.name)
            that.callback(archiveJson)
        }
        myReader.readAsArrayBuffer(File)
    }
    archiveJson(binArray, type, filename) {
        let archiveJson = {
            bytes: [],
            archive: []
        }
        for(let i in binArray) {
            const bytePosition = archiveJson.bytes.indexOf(binArray[i])
            if(bytePosition > -1) {
                archiveJson.archive.push(bytePosition)
            } else {
                archiveJson.bytes.push(binArray[i])
                archiveJson.archive.push(archiveJson.bytes.indexOf(binArray[i]))
            }
        }
        return {
            bytes: archiveJson.bytes,
            archive: archiveJson.archive.join(' '),
            type: type,
            filename: filename
        }
    }
    unarchiveFile(json) {
        const archive = json.archive.split(' ');
        let unarchive = []
        for(let i in archive) {
            unarchive.push(json.bytes[parseInt(archive[i])])
        }
        let decryptedByteArray = this.lexieEncryption.decryptObject(unarchive)
        let rez = []
        rez = [...decryptedByteArray].map((item, index) => {
            return parseInt(item, 2)
        })
        console.log(rez)
        return new Uint8Array(rez)
    }
}