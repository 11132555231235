import React from 'react';
import CoreComponent from "../../Core/CoreComponent";
import EmailTextField from "./MaterialComponents/EmailTextField";
import PasswordTextField from "./MaterialComponents/PasswordTextField";
import ConfirmPasswordTextField from "./MaterialComponents/ConfirmPasswordTextField";
import RegisterButton from "./MaterialComponents/RegisterButton";
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Style from "../LoginComponent/Styles/LoginComponentStyle";
import LoginRegisterDialog from './MaterialComponents/LoginRegisterDialog';
import ApiRequests from '../../Settings/ApiRequests'

export default class RegisterComponent extends CoreComponent {
    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.state = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            confirmPassword: '',
            dateOfBirth: '',
            sex: ''
        }
    }

    componentDidMount() {

        super.componentDidMount()

    }

    loadComponentsProps() {
        const classes = this.props
        this.setComponentsConstants('loginRegisterDialog', {
            labelEmail: this.getTranslation(this.props.language, 'LANG_EMAIL'),
            labelPassword: this.getTranslation(this.props.language, 'LANG_PASSWORD'),
            labelFirstName: this.getTranslation(this.props.language, 'LANG_FIRSTNAME'),
            labelLastName: this.getTranslation(this.props.language, 'LANG_LASTNAME'),
            labelConfirmPassword: this.getTranslation(this.props.language, 'LANG_CONFIRMPASSWORD'),
            labelDateOfBirth: this.getTranslation(this.props.language, 'LANG_DATEOFBIRTH'),
            labelRegisterButton: this.getTranslation(this.props.language, 'LANG_REGISTER'),
            labelLoginButton: this.getTranslation(this.props.language, 'LANG_LOGIN'),
            labelRegister: this.getTranslation(this.props.language, 'LANG_REGISTER'),
            labelLogin: this.getTranslation(this.props.language, 'LANG_LOGIN'),
            labelSex: this.getTranslation(this.props.language, 'LANG_SEX'),
            labelCancel: this.getTranslation(this.props.language, 'LANG_CANCEL'),
            valueEmail: this.state.email,
            valuePassword: this.state.password,
            valueConfirmPassword: this.state.confirmPassword,
            valueFirstName: this.state.firstName,
            valueLastName: this.state.lastName,
            valueDateOfBirth: this.state.dateOfBirth,
            valueSex: this.state.sex
        })
        .setComponentsMethods('loginRegisterDialog', {
            ajaxLogin: () => { this.ajaxLogin()},
            ajaxRegister: (object) => { this.ajaxRegister(object) },
            onChangePassword: (value) => { this.setPassword(value) },
            onChangeEmail: (value) => { this.setEmail(value) },
            onChangeFirstName: (value) => { this.setFirstName(value) },
            onChangeLastName: (value) => { this.setLastName(value) },
            onChangeConfirmPassword: (value) => { this.setConfirmPassword(value) },
            onChangeDateOfBirth: (value) => { this.setDateOfBirth(value) },
            onChangeSex: (value) => { this.setSex(value) },
        })
    }
    ajaxLogin() {

    }
    ajaxRegister() {
        const requestObject = {
            usersEmail: this.state.email,
            usersPassword: this.state.password,
            usersFirstName: this.state.firstName,
            usersLastName: this.state.lastName,
            usersConfirmPassword: this.state.confirmPassword,
            usersDateOfBirth: this.state.dateOfBirth,
            usersUsersSexID: this.state.sex
        }
        this.ajax(ApiRequests.registerUsers, requestObject, 'POST', 'ajaxRegister', (data) => {
        })
    }

    setPassword(value) {
        this.setState({password: value})
    }
    setEmail(value) {
        this.setState({email: value})
    }
    setFirstName(value) {
        this.setState({firstName: value})
    }
    setLastName(value) {
        this.setState({lastName: value})
    }
    setConfirmPassword(value) {
        this.setState({confirmPassword: value})
    }

    setDateOfBirth(value) {
        this.setState({dateOfBirth: value})
    }

    setSex(value) {
        this.setState({sex: value})
    }
    getRender() {
        return this.getRenderLoginRegisterDialog();
    }
    getRenderLoginRegisterDialog() {
        return <LoginRegisterDialog {...this.buildComponentProps('loginRegisterDialog')} />
    }
}