import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Languages from '../../../Settings/Languages'
import Style from "../Styles/LanguageNativeSelectStyle";
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function LanguageNativeSelect(props) {
    const classes = useStyles();
    if(window.location.pathname === '' || window.location.pathname === '') {
        {
            Object.keys(props.componentConstants.Routes).map((routesValue, routesIndex) => {
                if(props.componentConstants.Routes[routesValue].default === true) {
                    window.location.href = '/' + Languages[Languages[0]] + '/' + props.componentConstants.Routes[routesValue].url
                }
            })
        }
    }
    const handleChange = (event) => {
        const value = event.target.value;
        let currentLang = window.location.pathname.split('/')
        currentLang[1] = value
        window.location.href = currentLang.join('/')
    };


    const componentProps = {
        variant: 'contained',
        color: 'primary',
    }

    const componentConstants = {
        ...props.componentConstants
    }
    const componentMethods = {
        ...props.componentMethods
    }
    return (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="system-language">{componentConstants.label}</InputLabel>
                <Select
                labelId="system-language"
                onChange={handleChange}
                inputProps={{
                value:componentMethods.getManagerState().language,
                }}
                >
                    {
                        Object.keys(Languages).map((value, index) => {
                            return <MenuItem key={index} value={Languages[index]}>{Languages[index]}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
);
}