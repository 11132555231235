import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/EncryptButtonStyle";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AuthenticateButton (props) {
    const classes = useStyles();

    const componentProps = {
        ...props.componentProps,
        placeholder: "Text",
        color: "primary",
        variant: "contained",
        fullWidth: true
    }
    const componentMethods = {
        onClick: props.componentMethods.encryptText
    }
    const componentConstants = {
        ...props.componentConstants,
        text: props.componentConstants.text
    }
    return <Button {...componentProps} {...componentMethods}>
        {componentConstants.text} <KeyboardArrowDownIcon />
        </Button>
}