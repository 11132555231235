export default function Style(theme) {
    const style = {
        root: {
            flexGrow: 1,
            padding: 20
        },
        button: {
            marginTop: 10,
            marginRight: 10
        },
        textArea: {
            display: 'none'
        }
    }
    return style
}