export default function Style(theme) {
    const style = {
        root: {
            flexGrow: 1,
            padding: 20
        },
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            margin: '0 auto',
        },
        textAreaPassword: {
        },
        typographyCopied: {
            color: theme.palette.secondary.light
        }
    }
    return style
}