export default function Style(theme) {
    const style = {
        formControl: {
            margin: '0 ' + theme.spacing(1) + 'px',
            minWidth: 120,
            color: theme.palette.common.white
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            color: theme.palette.common.white
        },
    }
    return style
}